




























import { UserFilter } from "@/models/user-filter.model";
import { PaginatedResult } from "@/shared/types/paginated-result.class";
import Vue from "vue";
import Component from "vue-class-component";
import { ModelListSelect } from "vue-search-select";
import { namespace } from "vuex-class";

const userFiltersNamespace = namespace("UserFilters");
const mailsNamespace = namespace("Mails");

@Component({ components: { ModelListSelect } })
export default class SendMailToUserFilter extends Vue {
  isLoading = false;
  selectedFilter: UserFilter = new UserFilter();
  selectedMailTemplate = null;

  @mailsNamespace.Getter("all")
  mailTemplatesPaginated!: PaginatedResult<any>;

  @userFiltersNamespace.Getter("all")
  filters!: UserFilter[];

  @userFiltersNamespace.Action("fetchAll")
  fetchFilters!: () => Promise<UserFilter[]>;

  @mailsNamespace.Action("fetchAll")
  fetchMails!: () => Promise<any[]>;

  @mailsNamespace.Action("sendMailToFilter")
  sendMailToFilter!: (payload: any) => Promise<any[]>;

  get mailTemplates() {
    return this.mailTemplatesPaginated.data || [];
  }

  async sendMail() {
    this.isLoading = true;
    const payload = {
      filter: this.selectedFilter,
      template_id: this.selectedMailTemplate,
    };
    try {
      const count = await this.sendMailToFilter(payload);
      this.$root.$bvToast.toast(`Mails sent to ${count} mailbox(es)!`, {
        variant: "success",
      });
    } catch (e) {
      console.log(e);
      this.$root.$bvToast.toast(`There was an issue sending the mails`, {
        variant: "danger",
      });
    }
    this.isLoading = false;
  }

  async created() {
    this.isLoading = true;
    await this.fetchMails();
    await this.fetchFilters();
    this.isLoading = false;
  }
}
